import React, { Component } from "react";
import Slider from "react-slick";
import "./Videos.css";

export default class PauseOnHover extends Component {
  slider = React.createRef();

  state = {
    isSwiping: false
  };

  handleSwipeStart = (e) => {
    this.swipeStartX = e.touches[0].clientX;
    this.swipeStartY = e.touches[0].clientY;
    this.setState({ isSwiping: false });
  };

  handleSwipeMove = (e) => {
    const deltaX = e.touches[0].clientX - this.swipeStartX;
    const deltaY = e.touches[0].clientY - this.swipeStartY;

    if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
      this.setState({ isSwiping: true });
    }
  };

  handleSwipeEnd = (e) => {
    const deltaX = e.changedTouches[0].clientX - this.swipeStartX;

    if (Math.abs(deltaX) > 50) {
      if (deltaX < 0) {
        this.slider.current.slickNext();
      } else {
        this.slider.current.slickPrev();
      }
    }
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7777,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 6500,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 6500,
            pauseOnHover: true,
          }
        }
      ]
    };

    const videoIds = [
      '9r6ZDfzEr0Y'
    ];

    return (
      <section style={{ backgroundColor: 'white' }}>
        <div className="container" style={{ width: 'auto', zoom: '100%' }}>
        
        
        </div>
      </section>
    );
  }
}
