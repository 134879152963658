import React from 'react';
import "./NosPartenaires.css"

export default function NosPartenaires() {
  const partenaires = [
    { href: "https://www.ensiie.fr/", src: "images/partenaire/ensiie1.png" },
    { href: "https://polytech.univ-amu.fr/", src: "images/partenaire/poly1.png" },
    { href: "https://www.eilco-ulco.fr/", src: "images/partenaire/cote1.png" },
    { href: "https://www.isima.fr/", src: "images/partenaire/isima1.png" },
    { href: "https://www.univ-lyon2.fr/", src: "images/partenaire/lyon1.png" },
    { href: "https://www.univ-amu.fr/", src: "images/partenaire/amu.png" },
    { href: "https://www.univ-lorraine.fr/", src: "images/partenaire/lor1.png" },
    { href: "https://www.univ-paris8.fr/", src: "images/partenaire/paris81.png" },
    { href: "https://www.u-bourgogne.fr/", src: "images/partenaire/unvbou1.png" },
    { href: "https://www.univ-littoral.fr/", src: "images/partenaire/unvop1.png" },
    { href: "https://www.uca.fr/", src: "images/partenaire/uca1.png" },
    { href: "https://www.univ-paris13.fr/", src: "images/partenaire/paris131.png" },
    { href: "https://www.univ-lille.fr/", src: "images/partenaire/lille1.png" },
    { href: "https://www.lis-lab.fr/", src: "images/partenaire/lis1.png" },
  ];

  
  return (
    <div className="col-md-4">
      <div className="aside-bar">
        <div className="widget widget-search"></div>
        <div className="widget coures_instractor_area">
          <div className="instractor_heading" style={{ textAlign: 'center' }}>
            <h5 style={{ color: 'white' }}>NOS PARTENAIRES EN FRANCE</h5>
          </div>
          <div className="coures_instractor_thumb"></div>
          <div className="partenaires-grid" >
            {partenaires.map((partenaire, index) => (
              <div key={index} className="partenaire-item" style={{ textAlign: 'center' }}>
                <a href={partenaire.href} target="_blank" rel="noopener noreferrer">
                  <img className="img1" src={partenaire.src} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
