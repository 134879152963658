import { React, useEffect,useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import CookieConsent from "react-cookie-consent"; // Importer la bibliothèque
import NavbarMob from "./components/NavbarMob";
import Contactinfo from "./components/Contactinfo";
import Footer from "./components/footer";
import Footerstrip from "./components/footerstrip";
import Accueil from "./Page/Accueil";
import Slider from "./components/Slider/Slider";
import EtudAdmis from "./Page/EtudAdmis";
import Mot_president from "./Page/Apropos/Mot_president";

import Preinscription from "./Page/Preinscription/Preinscription";

import Conferences_ESISA from "./Page/Apropos/Conferences_ESISA";
import Conferences_etranger from "./Page/Apropos/Conferences_etranger";
import Etudier_ESISA from "./Page/Apropos/Etudier_ESISA";
import Objectifs_ESISA from "./Page/Apropos/Objectifs_ESISA";
import Themes_recherche from "./Page/Apropos/Themes_recherche";
import Voix_ESISA from "./Page/Apropos/Voix_ESISA";
import Workshop from "./Page/Apropos/Workshop";
import Systeme_enseignement from "./Page/Enseignement/Systeme_enseignement";
import Procedure_admission from "./Page/Enseignement/Procedure_admission";
import Bac3 from "./Page/Enseignement/Bac3";
import Bac5ISI from "./Page/Enseignement/Bac5ISI";
import Bac5ISITN from "./Page/Enseignement/Bac5ISITN";
import Bac5IS2IA from "./Page/Enseignement/Bac5IS2IA";
import Ccna from "./Page/Enseignement/Ccna";

import ESISA_International from "./Page/International/ESISA_International";
import Admis_Etranger from "./Page/International/Admis_Etranger";
import Nos_Partenaires from "./Page/International/Nos_Partenaires";

import PolitiqueDeCookies from "./components/PolitiqueDeCookies";
import Preferencecookies from "./components/preferencecookies";

import ESISAEntreprises from "./Page/EntreprisesStages/ESISAEntreprises";
import Stages from "./Page/EntreprisesStages/Stages";
import Emplois from "./Page/EntreprisesStages/Emplois";
import SocietesPartenaires from "./Page/EntreprisesStages/SocietesPartenaires";

import Galerie from "./Page/Autres/Galerie";
import Presse from "./Page/Autres/Presse";


import Preloader from "./components/Preloader"
import ReconnuEsisa from "./Page/reconnuEsisa/ReconnuEsisa";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Écouteur pour détecter quand tout le contenu est chargé
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Ajouter l'event listener
    window.addEventListener("load", handleLoad);

    // Nettoyer l'event listener
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);
  useEffect(() => {
    // Définir la fonction gtag dans la fenêtre globale
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    // Configurer gtag avec votre ID Google Ads
    gtag("config", "AW-16632715756");
  }, []);


  return (
    <div>
          {isLoading && <Preloader />} {/* Afficher l'animation si en chargement */}

      <div className="iq_wrapper">
        <header className="iq_header_1">
          <div className="container">
            <div
              className="iq_logo"
              style={{ display: "flex", alignItems: "center", padding: "1rem" }}
            >
              <a href="/">
                <img
                  style={{ width: "100%" }}
                  src="images/logo/esisa.png"
                  alt="Iqoniq Themes"
                />
              </a>
              <a href="/reconnuEsisa">
                <img
                  style={{ width: "50%" }}
                  src="images/logo/reconnu.png"
                  alt="Logo Reconnu"
                />
              </a>
            </div>
          </div>
          <div className="navigation-wrapper pull-left">
            <Navbar />
            <NavbarMob />
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Slider />} />
        </Routes>

        <div className="iq_content_wrap" style={{ backgroundColor: "white" }}>
          <section className="coures_single_page">
            <div className="container" style={{ width: "auto", zoom: "100%" }}>
              <Routes>
                <Route path="/" element={<EtudAdmis />} />
                <Route path="/Mot_president" element={<Mot_president />} />
                <Route
                  path="/Conferences_ESISA"
                  element={<Conferences_ESISA />}
                />
                <Route path="/politique-de-cookies" element={<PolitiqueDeCookies />} />
                <Route path="/parametres-de-cookies" element={<Preferencecookies />} />


                
                <Route
                  path="/Conferences_etranger"
                  element={<Conferences_etranger />}
                />
                <Route path="/Etudier_ESISA" element={<Etudier_ESISA />} />
                <Route
                  path="/Objectifs_ESISA"
                  element={<Objectifs_ESISA />}
                />
                <Route
                  path="/Themes_recherche"
                  element={<Themes_recherche />}
                />
                <Route path="/Voix_ESISA" element={<Voix_ESISA />} />
                <Route path="/Workshop" element={<Workshop />} />

                <Route
                  path="/Systeme_enseignement"
                  element={<Systeme_enseignement />}
                />
                <Route
                  path="/Procedure_admission"
                  element={<Procedure_admission />}
                />
                <Route path="/Bac3" element={<Bac3 />} />
                <Route path="/Bac5ISI" element={<Bac5ISI />} />
                <Route path="/Bac5IS2IA" element={<Bac5IS2IA />} />
                <Route path="/Bac5ISITN" element={<Bac5ISITN />} />
                <Route path="/PREINSCRIPTION" element={<Preinscription />} />

                <Route path="/Ccna" element={<Ccna />} />
                <Route
                  path="/ESISA_International"
                  element={<ESISA_International />}
                />
                <Route path="/Admis_Etranger" element={<Admis_Etranger />} />
                <Route path="/Nos_Partenaires" element={<Nos_Partenaires />} />
                <Route path="/reconnuEsisa" element={<ReconnuEsisa />} />
                <Route
                  path="/ESISAEntreprises"
                  element={<ESISAEntreprises />}
                />
                <Route path="/Stages" element={<Stages />} />
                <Route path="/Emplois" element={<Emplois />} />
                <Route
                  path="/SocietesPartenaires"
                  element={<SocietesPartenaires />}
                />
                {/* <Route path="/Presse" element={<Presse />} /> */}
                {/* Route par défaut pour les pages non trouvées */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </section>
        </div>

        <Routes>
          <Route path="/" element={<Accueil />} />
        </Routes>
        <Contactinfo />
        <Footer />
        <Footerstrip />
      </div>
      <CookieConsent
  location="bottom"
  buttonText="Accepter"
  declineButtonText="Refuser"
  enableDeclineButton
  onAccept={() => console.log("Cookies acceptés")}
  onDecline={() => console.log("Cookies refusés")}
  style={{ background: "#051951", color: "white" }}
  buttonStyle={{
    background: "#4cb6ea",
    color: "white",
    borderRadius: "5px",
  }}
  declineButtonStyle={{
    background: "grey",
    color: "white",
    borderRadius: "5px",
  }}
  cookieName="esisa_cookie_consent"
>
  Ce site utilise des cookies pour améliorer votre expérience.{" "}
  <a
    href="/parametres-de-cookies"
    style={{ color: "#4cb6ea", textDecoration: "underline" }}
  >
    Gérer vos préférences.
  </a>{" "}
  En continuant à naviguer, vous acceptez notre{" "}
  <a href="/politique-de-cookies" style={{ color: "#4cb6ea" }}>
    politique de cookies
  </a>
  .
</CookieConsent>

    </div>
  );
}

export default App;
