import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Procedure_admission() {
  return (
    <div class="row">
    <div class="col-md-8">							  
    <div class="course_single_heading">				
        <div class="iq_heading_1 text-left">
            <h4><span style={{fontSize:'25px'}}>PARCOURS ET PROCEDURE D'ADMISSION</span></h4>
        </div>
        <div class="course-rating"style={{overflow: 'scroll',height:'1000px',overflowX:'hidden'}}> 
        <p>L'admission à l'ESISA est ouverte aux candidats titulaires d'un baccalauréat scientifique, économique, technique, ou de diplômes équivalents. Le processus de sélection des candidats repose sur une approche rigoureuse inspirée des méthodes de recrutement des entreprises. Celui-ci comprend l'examen d'un dossier de candidature, un entretien individuel, ainsi qu'un test de mathématiques. Nous attachons une importance particulière aux compétences en mathématiques, considérées comme fondamentales pour réussir dans le domaine de l'informatique. Les entretiens permettent aux candidats de mettre en avant leurs compétences, leur personnalité, ainsi que leur motivation à rejoindre une institution de renom telle que l'ESISA.</p>
        <Gallery>

<Item original="images/final/final.png"
  thumbnail="images/final/final.png"
  width="750"
  height="1000"
>
  {({ ref, open }) => (
   
    <div className="course-rating" style={{ height: '100%', overflow: 'hidden' }}>
            <img 
              src="images/final/final.png" 
              alt="IS2IA Image"
              style={{ width: '100%', height: 'auto' }} 
            />
          </div>  
  )}
</Item></Gallery>

      

       </div>		
      </div>
      </div>
      <NosPartenaires />
                         
                              
      </div>
)
}
