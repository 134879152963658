import React from "react";
import Slider from "react-slick";
import "./Actualites.css";

export default function Actualites() {
  const actualites = [
    {
      imgSrc: "images/actu/cluster.jpg",
      altText: "Cluster C19",
      title: "Convention ESISA - C-19",
      description:
        "ESISA et Cluster C19 ont conclu une convention pour développer des jeux vidéo, marquant ainsi une collaboration innovante dans le domaine de l'éducation et du divertissement numérique.",
    }
,    
    {
      imgSrc: "images/actu/master.jpg",
      altText: "Nouveaux Masters",
      title: "Nouveaux Masters",
      description:
      "Nous sommes ravis de vous présenter deux nouveaux masters : le Master IS2IA, qui forme des experts en systèmes d'information, intelligence artificielle et machine learning pour créer des solutions innovantes, et le Master ISITN, qui prépare des spécialistes de la transformation digitale des entreprises avec une expertise en technologies émergentes et stratégies numériques.",},
     
    {
      imgSrc: "images/actu/analytica.jpg",
      altText: "ESISA Analytica",
      title: "Lancement du Laboratoire ESISA ANALYTICA",
      description:
        "ESISA est fière d'annoncer le lancement de son laboratoire 'ESISA ANALYTICA'. Ce laboratoire est dédié à l'application de l'intelligence artificielle dans des domaines clés tels que la finance, la médecine, et l'écologie. Il offrira aux étudiants et chercheurs une plateforme innovante pour développer des projets de pointe dans l'analyse financière, les diagnostics médicaux, et la protection de l'environnement grâce à l'IA.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="center-slider-wrap">
      <div className="container" style={{ width: "auto", zoom: "100%" }}>
        <div className="iq_heading_1 text-center">
          <h4>
            <span>ACTUALITÉS</span>
          </h4>
        </div>

        <Slider {...settings}>
          {actualites.map((actualite, index) => (
            <div className="center-dec" key={index}>
              <figure>
                <img className="actu" src={actualite.imgSrc} alt={actualite.altText} />
              </figure>
              <div className="text" style={{ height: "37rem" }}>
                <h5>
                  <a href="#">{actualite.title}</a>
                </h5>
                <p>{actualite.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
